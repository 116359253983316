import axios from 'axios';
import { useMemo } from 'react';
import { Config } from '../../../config';
import { JobModelItem } from '../../../app/modules/organization/jobs/Models';
import { useAuth } from '../../../app/modules/auth';
import { useQuery } from 'react-query';

/**
 * A candidate has an available interview if they have a job
 * and they do not have any pending or completed AI interviews
 * related to that job.
 */
function checkCandidateHasAvailableInterviews(
  interviewsData: { job_candidate_id: string }[] | undefined,
  jobsData: { job_candidate_id: string; organization_name: string }[] | undefined
) {
  return jobsData?.filter((job) => {
    return !interviewsData?.some(
      (interview) => interview?.job_candidate_id === job?.job_candidate_id
    );
  });
}

const MiniProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="h-[5px] w-full rounded-full bg-[#ffffff40]">
      <div style={{ width: `${progress}%` }} className={`block h-[5px] bg-white rounded-full`} />
    </div>
  );
};

interface T {
  step1Progress: number;
  step2Progress: number;
  step3Progress: number;
  step4Progress: number;
}

const CandidateBanner = (props: T) => {
  const { currentUser } = useAuth();

  const fetchJobs = async () => {
    const response = await axios.get(`${Config.api_url}/candidate/jobs`);
    return await response.data.value;
  };

  const { data: jobsData } = useQuery<JobModelItem[]>(
    ['customer.jobs', currentUser?.id],
    fetchJobs
  );

  const fetchInterviews = async () => {
    const response = await axios.get(
      `${
        Config.api_url
      }/candidate/ai-interview/list?interview_type=interview_ai&statuses[]=pending&statuses[]=completed&statuses[]=scoring&include_questions=0&candidate_id=${
        currentUser?.id as string
      }`
    );
    return response.data.data;
  };

  const { data: interviewsData } = useQuery<
    { job_candidate_id: string; organization_name: string }[]
  >(['candidate.ai-interview'], fetchInterviews);

  const availableInterviews = useMemo(() => {
    return checkCandidateHasAvailableInterviews(interviewsData, jobsData);
  }, [interviewsData, jobsData]);

  const nextStep = useMemo(() => {
    if (props.step1Progress < 100) {
      return { title: 'Finish Your Profile', link: '/candidate/profile/edit' };
    }

    if (props.step2Progress < 50) {
      return { title: 'Record VetAI Interview', link: '/candidate/profile/edit?tab=interview' };
    }

    if (props.step2Progress >= 50 && props.step3Progress < 100) {
      let title = 'Wait for a Match';

      if (availableInterviews?.length) {
        const firstAvailableInterviewSortedByOrgName = availableInterviews.sort((a, b) =>
          a.organization_name.localeCompare(b.organization_name)
        )?.[0]?.organization_name;

        title = `Record ${firstAvailableInterviewSortedByOrgName} Interview`;
      }

      if (jobsData?.length && !availableInterviews?.length) {
        title = 'Get Hired!';
      }

      return {
        title,
        link: '/candidate/interviews',
      };
    }

    if (props.step3Progress < 100) {
      return { title: 'Get Hired', link: '/candidate/jobs' };
    }

    if (props.step4Progress < 100) {
      return { title: 'Add Payment Method', link: '/candidate/payment/methods' };
    }

    if (props.step4Progress === 100) {
      return { title: 'You are all set!', link: '/candidate/welcome' };
    }
  }, []);

  /**
   * If they do not have any jobs matched, wait for a job match (50%)
   * If they have any job matched and any interview pending, wait for the interview (50%)
   * If they have any job matched and ALL interview completed, step is complete (100%)
   */
  const step2RealProgress = useMemo(() => {
    if (jobsData?.length && !availableInterviews?.length) {
      return 100; // All interviews are completed
    }

    if (jobsData?.length && availableInterviews?.length) {
      return 50; // Pending interviews
    }

    if (!jobsData?.length) {
      return 50; // No jobs matched
    }

    return props.step2Progress;
  }, [availableInterviews?.length, jobsData?.length]);

  return (
    <div className="!w-full !h-[85px] rounded-[12px] main-gradient mt-2 text-white px-[24px] py-[16px] grid grid-cols-12">
      <div className="col-span-4">
        <p className="text-priLight3 mb-1 text-body14 font-semibold">Next Step:</p>
        <h3 className="text-h3 font-extrabold text-white  hover:underline">
          <a href={nextStep?.link} className="text-white flex items-center cursor-pointer">
            {nextStep?.title}
            <i className="bi bi-box-arrow-up-right !ml-2 !text-white !font-bold" />
          </a>
        </h3>
      </div>

      <div className="col-span-8">
        <p className="text-priLight3 mb-1 text-body14 font-semibold">Progress:</p>
        <div className="flex justify-between gap-x-4">
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {props.step1Progress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Setup Profile
            </p>
            <MiniProgressBar progress={props.step1Progress} />
          </div>
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {step2RealProgress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Record Interview
            </p>
            <MiniProgressBar progress={step2RealProgress} />
          </div>
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {props.step3Progress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Get Hired
            </p>
            <MiniProgressBar progress={props.step3Progress} />
          </div>
          <div className="text-white font-bold text-body12 w-full">
            <p className="mb-0 ">
              {props.step4Progress === 100 && (
                <i className="bi bi-check-circle-fill !text-priLight3" />
              )}{' '}
              Add Payment Method
            </p>
            <MiniProgressBar progress={props.step4Progress} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateBanner;
