import React from 'react';

import { Button } from 'src/app/components/ui/button';
import { BrowserWebcam } from './BrowserWebcam';

export const InterviewPreviewPage = ({
  handleClose,
  handleStart,
}: {
  handleClose: () => void;
  handleStart: () => void;
}) => {
  return (
    <div className="fixed tailwind-container top-0 left-0 w-screen h-screen bg-white z-[199]">
      <div className="max-w-[1440px] px-[24px] mx-auto mt-[24px]">
        <div className="mb-[24px]">
          <img src="/media/logos/logo_dark_prpl.svg" alt="Distro" className="w-[120px]" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-[1.5fr_1fr] mt-[50px] gap-[24px]">
          <div className="flex flex-col gap-[16px]">
            <h1 className="text-h1 mb-0 font-extrabold text-left w-fit">Check Camera and Mic</h1>
            <div>
              <BrowserWebcam
                isPreview={true}
                isPractice={false}
                onCancel={() => {}}
                onSave={(file: File, transcript: string) => {
                  return new Promise((resolve) => {
                    resolve(true);
                  });
                }}
                setTimerSeconds={() => {}}
                setTimerStarted={() => {}}
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex flex-col w-[250px] justify-center gap-[16px]">
              <Button onClick={handleStart}>Start Interview</Button>
              <Button variant="secondary" onClick={handleClose}>
                Cancel Interview
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
