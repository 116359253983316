interface T {
  handleClose: () => void;
  handleStart: () => void;
  candidateName: string;
}

export const AreYouReady = ({ handleClose, handleStart, candidateName }: T) => {
  return (
    <div className="flex flex-col items-center px-8 py-2">
      <h3 className="text-h3 font-extrabold mt-8">Hi, {candidateName}!</h3>
      <p className="text-body14 text-center">
        Welcome back! In this stage, our customer will assess your technical skills for this role.
        Each question is tailored to your expertise and the job’s requirements.
      </p>
      <p className="text-body14 text-center">
        You’ll have 3 minutes per question and only one attempt per question. After time’s up, the
        next question will appear automatically. Make sure you’re ready before starting.
      </p>
      <p className="text-body14 text-center">
        If you have any questions, feel free to reach out first. Good luck!
      </p>

      <div className="flex gap-x-2 mb-8">
        <button
          onClick={handleClose}
          className="btn btn-light btn-active-light !text-darkGray !rounded-[12px]"
        >
          Close
        </button>

        <button
          onClick={handleStart}
          className="btn btn-light btn-active-light main-gradient !text-white !rounded-[12px]"
        >
          Start
        </button>
      </div>
    </div>
  );
};
