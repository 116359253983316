import React, { FC, useCallback, useState } from 'react';
import axios from 'axios';
import { Config } from '../../../../../config';
import moment from 'moment/moment';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';
import { Modal } from 'react-bootstrap';
import { AnimatedButton } from '../../../../../_theme/helpers/components/AnimatedButton';
import { CandidateWorkHistoryModel } from '../Models';
import { useQuery } from 'react-query';
import { useAuth } from '../../../auth';
import { LayoutSplashScreen } from '../../../../../_theme/layout/core';
import { WorkHistoryItem } from './WorkHistoryItem';
import clsx from 'clsx';

interface WorkHistoryItem {
  title: string;
  company_name: string;
  description: string;
  is_current: boolean;
  start_date_month: number;
  start_date_year: number;
  end_date_month: number;
  end_date_year: number;
}

const WorkHistoryItemDefaultValue = {
  title: '',
  company_name: '',
  description: '',
  is_current: false,
  start_date_month: parseInt(moment().month(1).format('MM')),
  start_date_year: moment().year(),
  end_date_month: parseInt(moment().month(1).format('MM')),
  end_date_year: moment().year(),
};

export const WorkHistory: FC<any> = (props) => {
  const { currentUser } = useAuth();
  const [processing, setProcessing] = useState<boolean>(false);
  const [showWorkHistoryModal, setShowWorkHistoryModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<string>('');
  const [workHistoryItem, setWorkHistoryItem] = useState<WorkHistoryItem>(
    WorkHistoryItemDefaultValue
  );

  const months = (): Array<{ value: number; label: string }> => {
    return moment.months().map((month, index) => ({
      value: index + 1,
      label: month,
    }));
  };

  const reset = () => {
    setWorkHistoryItem(WorkHistoryItemDefaultValue);
    setProcessing(false);
    setShowWorkHistoryModal(false);
  };

  const formatYearAndMonthToDate = (year: number, month: number): string => {
    const validDate = moment(`${year}-${String(month).padStart(2, '0')}`, 'YYYY-MM', true);

    if (!validDate.isValid()) throw new Error('Invalid date format');
    return validDate.format('YYYY-MM');
  };

  const handleAddWorkHistory = useCallback(async () => {
    if (processing) return;

    setProcessing(true);
    try {
      await axios.post(`${Config.api_url}/candidate/work-history`, {
        title: workHistoryItem.title,
        company_name: workHistoryItem.company_name,
        description: workHistoryItem.description,
        start_date: formatYearAndMonthToDate(
          workHistoryItem.start_date_year,
          workHistoryItem.start_date_month
        ),
        end_date: workHistoryItem.is_current
          ? null
          : formatYearAndMonthToDate(workHistoryItem.end_date_year, workHistoryItem.end_date_month),
        is_current: workHistoryItem.is_current,
      });
      toast.success('Work history saved successfully!');
      reset();
    } catch (e: any) {
      toast.error(e.response?.data.message || 'An error occurred, please try again.');
      captureException(e);
    } finally {
      setProcessing(false);
    }
  }, [workHistoryItem]);

  const handleEditWorkHistory = useCallback(async () => {
    if (processing) return;

    setProcessing(true);
    try {
      await axios.put(`${Config.api_url}/candidate/work-history/${isEdit}`, {
        title: workHistoryItem.title,
        company_name: workHistoryItem.company_name,
        description: workHistoryItem.description,
        start_date: formatYearAndMonthToDate(
          workHistoryItem.start_date_year,
          workHistoryItem.start_date_month
        ),
        end_date: workHistoryItem.is_current
          ? null
          : formatYearAndMonthToDate(workHistoryItem.end_date_year, workHistoryItem.end_date_month),
        is_current: workHistoryItem.is_current,
      });
      toast.success('Work history updated successfully!');
      reset();
    } catch (e: any) {
      console.log('e', e);
      toast.error(e.response?.data.message || 'An error occurred, please try again.');
      captureException(e);
    } finally {
      setProcessing(false);
    }
  }, [workHistoryItem]);

  const handleNewWorkHistoryInput = useCallback(
    (newData: Partial<any>) => {
      setWorkHistoryItem((existingData) => ({
        ...existingData,
        ...newData,
      }));
    },
    [workHistoryItem]
  );

  const handleDelete = useCallback(async (id: string) => {
    setProcessing(true);
    try {
      await axios.delete(`${Config.api_url}/candidate/work-history/${id}`);
      toast.success('Work History Deleted.', {
        duration: 2000,
      });
      await refetch();
    } catch (error: any) {
      let message = error.response.data.message;
      toast.error(message);
      captureException(error);
    }
    setProcessing(false);
  }, []);

  const fetchWorkHistory = async (): Promise<Array<CandidateWorkHistoryModel>> => {
    const response = await axios.get(`${Config.api_url}/candidate/work-history`);
    return response.data.value;
  };

  const { isLoading, data, isRefetching, refetch } = useQuery({
    queryKey: ['candidate.workhistory', currentUser?.id],
    queryFn: fetchWorkHistory,
  });

  if (isLoading || isRefetching || !data) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        className={'fade'}
        animation={true}
        show={showWorkHistoryModal}
      >
        <Modal.Body className={'modal-md !px-[32px] !py-[22px]'}>
          <h3 className="text-h3 font-extrabold">Add Work History</h3>
          <span className="block text-body12 !mb-4">Tell us about your past job</span>

          <div className="col-md-12 mt-2">
            <label className="align-items-center">
              <span className="required text-body14 font-semibold">Title</span>
            </label>

            <input
              type="text"
              defaultValue={workHistoryItem.title}
              onChange={(e) =>
                handleNewWorkHistoryInput({
                  title: e.target.value,
                })
              }
              className="form-control form-control-lg "
              placeholder="e.g. Senior Product Manager"
            />
          </div>

          <div className="col-md-12 mt-2">
            <label className="align-items-center">
              <span className="required text-body14 font-semibold">Company Name</span>
            </label>
            <input
              type="text"
              defaultValue={workHistoryItem.company_name}
              onChange={(e) =>
                handleNewWorkHistoryInput({
                  company_name: e.target.value,
                })
              }
              className="form-control form-control-lg "
              placeholder="e.g. Google"
            />
          </div>

          <div className="mb-10">
            <label className="form-check form-check-custom form-check-inline form-check-solid me-5 mb-7 !flex !items-center mt-4">
              <input
                className="form-check-input me-1"
                name="communication[]"
                type="checkbox"
                value="1"
                checked={workHistoryItem.is_current}
                onChange={(e) =>
                  handleNewWorkHistoryInput({
                    is_current: e.target.checked,
                  })
                }
              />
              <span className="block text-body12 font-bold">I am currently working here</span>
            </label>

            <label className="align-items-center fs-5 fw-semibold mb-2">
              <span className="required  text-body14 font-semibold">Start Date</span>
            </label>
            <div className="row mb-10">
              <div className="col-md-6">
                <select
                  value={workHistoryItem.start_date_month} // Directly bind the value from the state
                  onChange={(e) => {
                    handleNewWorkHistoryInput({
                      start_date_month: parseInt(e.target.value, 10), // Ensure the value is an integer
                    });
                  }}
                  className="form-control form-select"
                  placeholder="Month"
                >
                  {months().map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <select
                  value={workHistoryItem.start_date_year} // Directly bind the value from the state
                  onChange={(e) =>
                    handleNewWorkHistoryInput({
                      start_date_year: parseInt(e.target.value, 10), // Ensure the value is an integer
                    })
                  }
                  className="form-control form-select"
                  placeholder="Year"
                >
                  {Array.from({ length: 40 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className={clsx({ 'd-none': workHistoryItem.is_current })}>
              <label className="align-items-center fs-5 fw-semibold mb-2">
                <span className="required  text-body14 font-semibold">End Date</span>
              </label>
              <div className="row mb-10">
                <div className="col-md-6">
                  <select
                    value={workHistoryItem.end_date_month} // Directly bind the value from the state
                    onChange={(e) => {
                      handleNewWorkHistoryInput({
                        end_date_month: parseInt(e.target.value, 10), // Ensure the value is an integer
                      });
                    }}
                    className="form-control form-select"
                    placeholder="Month"
                  >
                    {months().map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <select
                    value={workHistoryItem.end_date_year} // Directly bind the value from the state
                    onChange={(e) =>
                      handleNewWorkHistoryInput({
                        end_date_year: parseInt(e.target.value, 10), // Ensure the value is an integer
                      })
                    }
                    className="form-control form-select"
                    placeholder="Year"
                  >
                    {Array.from({ length: 40 }, (_, i) => {
                      const year = new Date().getFullYear() - i;
                      return (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <label className="align-items-center fs-5 fw-semibold mb-2">
              <span className="required  text-body14 font-semibold">Description</span>
              <span className="block text-body12 text-lightGray2">
                Please describe the skilled used at this role and work performed
              </span>
            </label>
            <div className="row mb-10">
              <div className="col-md-12">
                <textarea
                  rows={5}
                  defaultValue={workHistoryItem.description}
                  onChange={(e) =>
                    handleNewWorkHistoryInput({
                      description: e.target.value,
                    })
                  }
                  className={'form-control form-text'}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer !m-0 !p-0 pb-0 !border-none">
            <button
              type="button"
              disabled={processing}
              className="btn btn-light btn-active-light-primary btn-sm main-button"
              data-bs-dismiss="modal"
              onClick={() => setShowWorkHistoryModal(false)}
            >
              Cancel
            </button>
            <AnimatedButton
              onClick={isEdit ? handleEditWorkHistory : handleAddWorkHistory}
              type="button"
              processing={processing}
              disabled={processing}
              className="btn-sm btn btn-primary main-button main-gradient !text-white"
            >
              Save Experience
            </AnimatedButton>
          </div>
        </Modal.Body>
      </Modal>

      <div className="card">
        <div className="card-body">
          <div
            className={clsx(
              'cursor-pointer flex flex-col justify-center items-center w-full p-[15px] rounded-[10px] !border-dashed !border-pri'
            )}
            style={{ border: '1px dashed #D3D3D3' }}
            onClick={() => {
              setIsEdit('');
              setShowWorkHistoryModal(true);
              setWorkHistoryItem(WorkHistoryItemDefaultValue);
            }}
          >
            <i className="bi bi-plus-lg !text-pri !text-h1 mb-2"></i>
            <span className="text-body14 font-bold">Work History</span>
            <span className="text-body12 text-darkGray">
              By showcasing your work history, you're painting a vivid picture of your skills,
              experiences, and growth over time.
            </span>
          </div>
          <p className="text-lightGray2 font-semibold text-body14 mt-6">
            Work History ({data.length})
          </p>
          {data.map((work) => {
            return (
              <div className="flex items-center" key={work.id}>
                <div className={'col-md-11'}>
                  <WorkHistoryItem data={work} />
                </div>
                <div className={'col-md-1 text-end'}>
                  <button
                    onClick={() => {
                      setIsEdit(work.id); // Set the editing state with the work ID

                      // Use moment.utc() to handle UTC dates correctly
                      const startDate = moment.utc(work.start_date);
                      const endDate = work.end_date ? moment.utc(work.end_date) : moment();

                      // Log the parsed work history item for debugging
                      console.log('Parsed work history item:', {
                        ...work,
                        start_date_month: parseInt(startDate.format('MM')),
                        start_date_year: parseInt(startDate.format('YYYY')),
                        end_date_month: parseInt(endDate.format('MM')),
                        end_date_year: parseInt(endDate.format('YYYY')),
                      });

                      // Update the workHistoryItem state with correct values
                      setWorkHistoryItem({
                        ...work,
                        start_date_month: parseInt(startDate.format('MM')),
                        start_date_year: parseInt(startDate.format('YYYY')),
                        end_date_month: parseInt(endDate.format('MM')),
                        end_date_year: parseInt(endDate.format('YYYY')),
                      });

                      // Open the modal for editing
                      setShowWorkHistoryModal(true);
                    }}
                    type="button"
                    disabled={processing}
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary !bg-white"
                  >
                    <i className="bi bi-pencil-fill" />
                  </button>

                  <button
                    onClick={() => handleDelete(work.id)}
                    type="button"
                    disabled={processing}
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary !bg-white"
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
