/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useAuth } from '../../auth';
import axios from 'axios';
import { Config } from '../../../../config';
import { Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { LayoutSplashScreen, LayoutToolbarButtons } from '../../../../_theme/layout/core';
import { InterviewsNav } from './components/InterviewsNav';
import { EmptyInterviews } from './components/EmptyInterviews';
import { Link } from 'react-router-dom';
import { InterviewAIInterview } from './components/InterviewAI';
import { JobModelItem } from '../../organization/jobs/Models';
import { RequestAnotherInterview } from '../AiInterviews/RequestAnotherInterview';

export default function Interviews() {
  const { currentUser } = useAuth();
  const [show, setShow] = useState(false);
  const [requestAnother, setRequestAnother] = useState(false);
  const [jobCandidateId, setJobCandidateId] = useState('');

  const fetchJobs = async () => {
    const response = await axios.get(`${Config.api_url}/candidate/jobs`);
    return await response.data.value;
  };

  const { isLoading, data } = useQuery<JobModelItem[]>(
    ['customer.jobs', currentUser?.id],
    fetchJobs
  );

  const fetchInterviews = async () => {
    const response = await axios.get(
      `${
        Config.api_url
      }/candidate/ai-interview/list?interview_type=interview_ai&statuses[]=pending&statuses[]=completed&statuses[]=scoring&include_questions=0&candidate_id=${
        currentUser?.id as string
      }`
    );
    return response.data.data;
  };

  const { data: interviewsData } = useQuery<
    { job_candidate_id: string; organization_name: string }[]
  >(['candidate.ai-interview'], fetchInterviews);

  if (isLoading || !data) {
    return <LayoutSplashScreen />;
  }

  /**
   * Sort interviews to completed ones are at the end,
   * the others are sorted by organization_name
   */
  const sortedData = data.sort((a, b) => {
    if (
      interviewsData?.find((i) => i.job_candidate_id === a.job_candidate_id) &&
      !interviewsData?.find((i) => i.job_candidate_id === b.job_candidate_id)
    ) {
      return 1;
    } else if (
      !interviewsData?.find((i) => i.job_candidate_id === a.job_candidate_id) &&
      interviewsData?.find((i) => i.job_candidate_id === b.job_candidate_id)
    ) {
      return -1;
    } else {
      return a.organization_name.localeCompare(b.organization_name);
    }
  });

  return (
    <>
      <LayoutToolbarButtons>
        <div className="flex flex-col">
          <h1 className="text-h1 font-extrabold">Interviews</h1>
          <Link className="text-body14 font-medium text-lightGray2" to={`/candidate/welcome`}>
            Dashboard
          </Link>
        </div>
      </LayoutToolbarButtons>
      <div className="card mb-7">
        <InterviewsNav />
      </div>

      <InterviewAIInterview
        show={show}
        setShow={setShow}
        jobCandidateId={jobCandidateId}
        userName={currentUser?.first_name as string}
      />

      {sortedData?.length === 0 ? (
        <EmptyInterviews />
      ) : (
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-body12 font-bold text-priBlack uppercase bg-[#F8FAFF]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Invitee
                </th>
                <th scope="col" className="px-6 py-3">
                  Company & Role
                </th>
                <th scope="col" className="px-6 py-3">
                  Job
                </th>
                <th scope="col" className="px-6 py-3">
                  Notes
                </th>
                <th scope="col" className="px-6 py-3" />
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((v) => (
                <tr key={v.id} className="border-b border-lightGray text-priBlack">
                  <th
                    scope="row"
                    className="px-6 py-4 text-body14 font-medium flex items-center gap-x-4"
                  >
                    <div>
                      <img
                        alt="Distro"
                        width={38}
                        height={38}
                        src="/media/logos/arrow_purple.svg"
                      />
                    </div>
                    <div>
                      <p className="text-body15 font-semibold mb-0">Distro</p>
                      <p className="text-body12 mb-0">rebeca@distro.io</p>
                    </div>
                  </th>

                  <td className="px-6 py-4 text-body12 font-medium">
                    <p className="text-body12 font-semibold mb-0">{v.organization_name}</p>
                    <p className="text-body12 mb-0">{v.title}</p>
                  </td>

                  <td className="px-6 py-4 text-body12 font-medium">
                    <a
                      className="break-words flex whitespace-nowrap gap-x-2 items-center text-darkGray cursor-pointer hover:!text-pri"
                      href={`/candidate/jobs/${v.id}?from=interviews`}
                    >
                      View Job <i className="bi bi-box-arrow-up-right !text-pri" />
                    </a>
                  </td>

                  <td className="px-6 py-4 text-body12 font-medium text-darkGray break-words">
                    Congratulations! You’re onto the next stage. Prepare before you start!
                  </td>

                  <td className="px-6 py-4 text-body12 font-medium">
                    {/* Check if the interview has been done */}
                    {interviewsData?.find((i) => i.job_candidate_id === v.job_candidate_id) ? (
                      <div className="bg-green w-fit px-[12px] py-[10px] rounded-[8px] flex items-center gap-x-2 text-white">
                        <i className="bi bi-camera-video-fill !text-white" />
                        Completed
                        <button onClick={() => setRequestAnother(true)}>
                          <i className="bi bi-three-dots-vertical !text-white" />
                        </button>
                      </div>
                    ) : (
                      <button
                        className="bg-priLight00 w-fit px-[12px] py-[10px] rounded-[8px] flex items-center gap-x-2 text-pri hover:bg-priLight0"
                        onClick={() => {
                          setShow(true);
                          setJobCandidateId(v.job_candidate_id);
                        }}
                      >
                        <i className="bi bi-camera-video !text-pri !font-bold !text-xl" />
                        Record
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        centered
        backdrop="static"
        keyboard={false}
        className={'fade !p-0'}
        animation={true}
        show={requestAnother}
      >
        <RequestAnotherInterview
          interviewType="InterviewAI"
          handleClose={() => setRequestAnother(false)}
        />
      </Modal>
    </>
  );
}
