import { useEffect, useMemo, useState } from 'react';
import { BrowserWebcam } from './BrowserWebcam';
import { AiQuestion, PRACTICE_QUESTIONS, QUESTION_TIMER } from './constants';
// import { InterviewFinish } from './InterviewFinish';
import { Config } from '../../../../config';
import Joyride from 'react-joyride';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Button } from 'src/app/components/ui/button';

interface T {
  candidateId: string;
  jobCandidateId?: string; // only if interviewType is interview_ai
  interviewType: 'vet_ai' | 'interview_ai';
  isPractice?: boolean;
  handleFinish: () => void;
  handleCancel: () => void;
  joyrideSteps?: { target: string; content: JSX.Element }[];
  defaultQuestions?: AiQuestion[];
}

export const InterviewScreen = ({
  candidateId,
  jobCandidateId,
  interviewType,
  isPractice = false,
  handleFinish,
  handleCancel,
  joyrideSteps,
  defaultQuestions,
}: T) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [timerSeconds, setTimerSeconds] = useState(QUESTION_TIMER);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const [aiQuestions, setAiQuestions] = useState<AiQuestion[]>([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement>();

  const handleStopRecording = () => {
    document.getElementById('stop-recording-btn')?.click();
  };

  const selectedQuestions = useMemo(() => {
    return isPractice ? PRACTICE_QUESTIONS : [...(defaultQuestions || []), ...aiQuestions];
  }, [aiQuestions, isPractice, defaultQuestions]);

  useEffect(() => {
    // Get questions from the API
    if (!isPractice) {
      const fetchQuestions = async () => {
        const response = await axios.post(`${Config.api_url}/candidate/ai-interview/create`, {
          ...(interviewType === 'vet_ai'
            ? { candidate_id: candidateId }
            : { job_candidate_id: jobCandidateId, candidate_id: candidateId }),
          interview_type: interviewType,
        });

        const aiInterviewId = response.data.data.ai_interview_id;

        const questions = await axios.get(
          `${Config.api_url}/candidate/ai-interview/view/${aiInterviewId}`
        );

        const parsedQuestions: AiQuestion[] = questions?.data?.data?.questions?.map(
          (question: any) => {
            return {
              questionId: question.question_id,
              text: question.question,
              useTimer: true,
              title: question.question_title,
            };
          }
        );

        setAiQuestions(parsedQuestions);

        // play the first default audio after 3 seconds
        if (defaultQuestions?.[0]?.playDefaultAudio) {
          setTimeout(() => {
            setAudio(new Audio(defaultQuestions?.[0]?.audioURL));
          }, 3000);
        }
      };

      fetchQuestions();
    }
  }, []);

  useEffect(() => {
    let interval: any = null;
    if (timerStarted) {
      if (timerSeconds <= 0) {
        clearInterval(interval);
        handleStopRecording();
      } else {
        interval = setInterval(() => {
          setTimerSeconds(timerSeconds - 1);
        }, 1000);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [timerStarted, timerSeconds]);

  const timeRemaining = () => {
    const h = Math.floor(timerSeconds / 3600)
        .toString()
        .padStart(2, '0'),
      m = Math.floor((timerSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0'),
      s = Math.floor(timerSeconds % 60)
        .toString()
        .padStart(2, '0');

    return m + ':' + s;
  };

  const handleSaveVideoApiCalls = async (questionIndex: number, file: File, transcript: string) => {
    // Custom file name for S3
    const customFileName = `${interviewType}-question-${selectedQuestions?.[questionIndex]
      ?.questionId}-user-${candidateId}_${new Date().getTime()}`;

    const fullLocation = `https://${Config.services.aws.bucket}.s3.amazonaws.com/${customFileName}`;

    // Step 1: Get presigned URL
    let presignedUrl = '';
    try {
      const s3response = await axios.post(`${Config.api_url}/generate-presigned-url`, {
        fileName: customFileName,
        fileType: file.type,
      });

      presignedUrl = s3response?.data?.url;
    } catch (e) {
      console.error('Error Generating S3 Presigned URL', e);
    }

    // Step 2: Upload file to S3
    try {
      await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
    } catch (e) {
      console.error('Error Uploading Video to S3', e);
    }

    // Step 3: Upload S3 location to the API
    // This API will be called regardless of the success of the previous steps
    try {
      axios.post(`${Config.api_url}/candidate/profile/video`, {
        question_id: selectedQuestions[questionIndex].questionId,
        path: fullLocation,
        convert_to_mp4: true,
        transcription: transcript,
      });
    } catch (e) {
      console.error('Error Uploading Video to API', e);
      toast.error('Error saving video. Your interview might not be saved.');
    }
  };

  const handleSaveVideo: (file: File, transcript: string) => Promise<boolean> = async (
    file,
    transcript
  ) => {
    return new Promise(async (resolve) => {
      setCurrentIndex((prev) => {
        /**
         * We are doing all the API calls here to save the video
         * We do it inside the setCurrentIndex to make sure the video
         * index is updated before saving the video
         */

        try {
          // use timer can also be like save question
          if (selectedQuestions[prev].useTimer) {
            handleSaveVideoApiCalls(prev, file, transcript);
          }

          // if its the last questions, resolve false to not start recording again
          const wasLastQuestion = prev === selectedQuestions.length - 1;
          resolve(!wasLastQuestion); // if true, start recording again

          return prev + 1;
        } catch (e) {
          console.error('Error saving video', e);
          return prev;
        }
      });
    });
  };

  const currentQuestion = selectedQuestions[currentIndex];

  /**
   * Autoplay audio when the question changes
   * and stop it when the component unmounts
   */
  useEffect(() => {
    if (audio) {
      audio.play();
    }
    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, [audio]);

  /**
   * Fetch audio for the current question
   */
  useEffect(() => {
    if (audio) {
      audio.pause();
    }
    if (currentQuestion && currentQuestion?.text && !currentQuestion?.audioURL) {
      axios
        .get(`${Config.api_url}/candidate/audio?text=${currentQuestion?.text}`)
        .then((response) => {
          const byteCharacters = atob(response.data.value.file);
          const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'audio/mpeg' });

          const url = URL.createObjectURL(blob);
          const newAudio = new Audio(url);
          setAudio(newAudio);
        })
        .catch((e) => console.error('Error fetching audio', e));
    }
  }, [currentQuestion]);

  if (currentIndex > 0 && currentIndex >= selectedQuestions.length) {
    console.log('hree');
    handleFinish();
    return <></>;
  }

  if (!isPractice && aiQuestions.length === 0) {
    return <div className="text-center font-bold text-h1 mt-8">Loading...</div>;
  }

  return (
    <>
      {joyrideSteps && (
        <Joyride
          showProgress
          showSkipButton
          hideCloseButton
          scrollToFirstStep
          continuous
          run={isPractice}
          steps={joyrideSteps}
          styles={{
            buttonNext: { backgroundColor: '#6C5DD3', borderRadius: '8px' },
            buttonBack: { color: '#6C5DD3' },
            spotlight: { borderRadius: '20px' },
            tooltip: { borderRadius: '20px' },
            overlay: { zIndex: 199 },
          }}
          floaterProps={{ styles: { footer: { zIndex: 99999 }, wrapper: { zIndex: 9999999 } } }}
          locale={{
            last: 'Finish',
            open: 'Start Tutorial',
          }}
        />
      )}

      <div className="max-w-[1440px] px-[24px] mx-auto mt-[24px]">
        <img src="/media/logos/logo_dark_prpl.svg" alt="Distro" className="w-[120px]" />
        <h1 id="question-type" className="text-h1 mt-8 font-extrabold text-left w-fit">
          {currentQuestion.title}
        </h1>
      </div>

      <section className="flex w-full items-center justify-center max-w-[1440px] px-[24px] mx-auto">
        <div className="grid grid-cols-[1.5fr_1fr] gap-[24px]">
          <div className="">
            <div className="flex justify-between px-6 py-2">
              {currentQuestion.useTimer ? (
                <div id="timer" className="flex items-center">
                  <i className="bi bi-clock-history !text-4xl"></i>
                  <span className="ml-2 text-h3 font-semibold mr-1">{timeRemaining()}</span>
                  <span className="text-body12 mt-1">Sec </span>
                  <span className="text-body12 text-pri mt-1">/ Left</span>
                </div>
              ) : (
                <span />
              )}

              <div id="progress" className="flex items-center">
                <p className="text-body14 font-medium mb-0 mr-4">Progress</p>

                <div className="flex gap-x-1">
                  {selectedQuestions?.map((_, index) => (
                    <span
                      key={index}
                      className={`block w-[32px] h-[5px] ${
                        index <= currentIndex ? 'bg-pri' : 'bg-darkGray'
                      } rounded-full`}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div id="camera">
              <BrowserWebcam
                isPractice={isPractice}
                useTimer={currentQuestion.useTimer}
                width={830}
                height={512}
                maxDurationSeconds={QUESTION_TIMER}
                onCancel={() => {}}
                onSave={handleSaveVideo}
                setTimerSeconds={setTimerSeconds}
                setTimerStarted={setTimerStarted}
              />
            </div>
          </div>

          <div id="ai-interviewer" className="w-full text-left relative">
            <div className="flex justify-between px-6 py-2">
              <h5 className="text-body15 font-medium mb-0 text-pri flex items-center">
                AI Interviewer <i className="bi invisible bi-clock-history !text-4xl"></i>
              </h5>
            </div>

            <div className="border-2 rounded-[20px] border-priLight01 h-[498px] px-3 py-4 relative">
              <img
                src="/media/rebeca.png"
                alt="Rebeca"
                className="w-[95px] h-[95px] absolute right-4 shadow-xs top-0 -translate-y-1/2 rounded-3xl"
                id="ai-interviewer-camera"
              />
              <p className="text-lightGray2 font-medium text-body15">Being reviewed by Rebeca</p>

              {/* Dynamic Text */}
              <p className="text-[28px] font-normal h-[440px] overflow-y-scroll whitespace-pre-wrap">
                {currentQuestion.text}
              </p>
            </div>

            <div className="grid grid-cols-3 gap-4 mt-4 mx-auto ">
              <Button id="cancel-button" variant="secondary" onClick={() => setCancelModal(true)}>
                Cancel Interview
              </Button>
              <Button
                id="next-button"
                variant="outline"
                onClick={isPractice ? handleFinish : handleStopRecording}
                disabled={
                  !isPractice &&
                  ((timerStarted && timerSeconds > QUESTION_TIMER - 0) || !timerStarted)
                }
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </section>

      <Modal
        centered
        show={cancelModal}
        backdrop="static"
        keyboard={false}
        className={'fade !p-0'}
        animation={true}
      >
        <Modal.Body>
          <h3 className="text-h3 font-medium text-center">Cancel Confirmation</h3>
          Closing now will discard your progress. You have one more attempt to record your
          introduction video, so please make sure you’re ready before closing.
          <div className="mt-8 flex justify-center gap-x-2">
            <button
              id="next-button"
              className="btn btn-outline btn-danger !border "
              onClick={() => handleCancel()}
            >
              Cancel
            </button>

            <button
              id="next-button"
              className="btn btn-outline btn-active-light main-gradient !text-white"
              onClick={() => setCancelModal(false)}
            >
              Keep Progress
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
