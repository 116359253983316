import { NavLink } from 'react-router-dom';

export const InterviewsNav = () => {
  return (
    <div className="card-header !min-h-[40px] !p-0 !m-0 !mt-2">
      <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder">
        <li className="nav-item h-12">
          <NavLink
            end
            to={`/candidate/interviews`}
            className="nav-link text-active-dark text-hover-dark !font-bold !text-body15"
          >
            Invitations
          </NavLink>
        </li>
        <li className="nav-item h-12">
          <NavLink
            end
            to={`/candidate/interviews/history`}
            className="nav-link text-active-dark text-hover-dark !font-bold !text-body15"
          >
            History
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
