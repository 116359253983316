export const QUESTION_TIMER = 60 * 3; // 3 minutes

export type VetAIInterviewType = {
  ai_interview_id: string;
  candidate_id: string;
  job_candidate_id: string;
  interview_type: string;
  questions: [];
  status: string;
  total_score: number;
  total_score_description: string;
};

export type AiQuestion = {
  questionId: string;
  title: string;
  text: string;
  useTimer: boolean;
  audioURL?: string;
  playDefaultAudio?: boolean;
};

export const PRACTICE_QUESTIONS: AiQuestion[] = [
  {
    questionId: 'practice',
    title: 'Hobbies and Interests',
    text: 'What do you enjoy doing in your free time, and how do your hobbies or interests influence your approach to work or problem-solving?',
    useTimer: true,
    playDefaultAudio: false,
  },
];

export const DEFAULT_QUESTIONS: AiQuestion[] = [
  {
    questionId: 'default-1',
    title: 'Introducing Distro and our work!',
    text: `Hi there, and welcome! I’m thrilled to have you here today.\n\nLet me introduce you to Distro, the platform designed to connect top talent like you with incredible job opportunities.\n\nAt Distro, we make hiring simple and efficient. Our AI-driven platform helps you showcase your skills and connect with top employers faster. Features like the Introduction Interview and Company AI Interview streamline the process, matching you with the right roles while saving time and effort.\n\nFor this Introduction Interview, you’ll have up to 3 minutes to answer each question, which will be tailored dynamically to your profile. If you finish before the timer runs out, you can click ‘Next’ to move on. If the time runs out and you haven’t advanced, don’t worry—the system will automatically take you to the next question.\n\nThis is a great chance to showcase your unique talents and make an impression. Let’s dive in and get started!`,
    useTimer: false,
    playDefaultAudio: true,
    audioURL:
      'https://app-assets-distro-sandbox-1-us-east-2.s3.us-east-2.amazonaws.com/vetai-welcome.mp3',
  },
];

export const DEFAULT_QUESTIONS_APPLICANT: AiQuestion[] = [
  {
    questionId: 'default-1',
    title: 'Introducing Distro and our work!',
    text: `Hi there, and welcome! I’m excited to have you here today as part of your journey toward an exciting opportunity.\n\nDistro’s platform uses AI-powered tools to streamline the hiring process, giving you a seamless way to showcase your expertise and stand out to our customer by responding to dynamic, profile-based questions.\n\nYou’ve applied for a specific role, and today, you’ll be recording an AI interview that will assess both your soft and hard skills.\n\nThis interview will take about 10 to 15 minutes and is a fantastic opportunity for you to demonstrate why you’re a great fit for the position.\n\nFor each question, you’ll have up to 3 minutes to answer. If you finish early, you can click ‘Next’ to move on. If time runs out before you advance, the system will automatically proceed to the next question.\n\nLet’s get started and help you move closer to landing your ideal role!`,
    useTimer: false,
    playDefaultAudio: true,
    audioURL:
      'https://app-assets-distro-sandbox-1-us-east-2.s3.us-east-2.amazonaws.com/vetai-welcome-applicant.mp3',
  },
];
