/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useAuth } from '../../auth';
import axios from 'axios';
import { Config } from '../../../../config';
import { useQuery } from 'react-query';
import { LayoutSplashScreen, LayoutToolbarButtons } from '../../../../_theme/layout/core';
import { InterviewsNav } from './components/InterviewsNav';
import { Link } from 'react-router-dom';
import { EmptyInterviews } from './components/EmptyInterviewsHistory';
import { VetAIInterviewType } from '../AiInterviews/constants';
import { JobModelItem } from '../../organization/jobs/Models';

export default function InterviewsHistory() {
  const { currentUser } = useAuth();

  const fetchJobs = async () => {
    const response = await axios.get(`${Config.api_url}/candidate/jobs`);
    return await response.data.value;
  };

  const { data: jobsData } = useQuery<JobModelItem[]>(
    ['customer.jobs', currentUser?.id],
    fetchJobs
  );

  const fetchInterviews = async () => {
    const response = await axios.get(
      `${Config.api_url}/candidate/ai-interview/list?candidate_id=${currentUser?.id}&include_questions=0`
    );
    return response.data.data;
  };

  const { isLoading, data } = useQuery<VetAIInterviewType[]>(
    ['candidate.ai-interview', currentUser?.id],
    fetchInterviews
  );

  if (isLoading || !data) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <LayoutToolbarButtons>
        <div className="flex flex-col">
          <h1 className="text-h1 font-extrabold">Interviews</h1>
          <Link className="text-body14 font-medium text-lightGray2" to={`/candidate/welcome`}>
            Dashboard
          </Link>
        </div>
      </LayoutToolbarButtons>
      <div className="card mb-7">
        <InterviewsNav />
      </div>

      {data?.length === 0 ? (
        <EmptyInterviews />
      ) : (
        <div className="relative overflow-x-auto">
          <h3 className="text-h3 font-bold">VetAI</h3>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 mb-16">
            <thead className="text-body12 font-bold text-priBlack uppercase bg-[#F8FAFF]">
              <tr>
                {/* <th scope="col" className="px-6 py-3">
                  Interview Type
                </th> */}
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Reason
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.filter((v) => v?.interview_type === 'vet_ai')
                ?.map((v) => (
                  <tr key={v.ai_interview_id} className="border-b border-lightGray">
                    {/* <th scope="row" className="px-6 py-4 text-body14 font-medium text-darkGray">
                    {v.interview_type === 'vet_ai' ? 'VetAI' : 'InterviewAI'}
                  </th> */}
                    <td className="px-6 py-4 text-body12 font-medium text-darkGray capitalize">
                      {v.status}
                    </td>
                    <td className="px-6 py-4 text-body12 font-medium text-darkGray">
                      {v.status === 'failed'
                        ? 'Something went wrong with this interview. You might need to record it again. Sorry for the inconveniece!'
                        : v.total_score_description}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <h3 className="text-h3 font-bold">InterviewAI</h3>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-body12 font-bold text-priBlack uppercase bg-[#F8FAFF]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Company & Role
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Reason
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.filter((v) => v?.interview_type === 'interview_ai')
                ?.map((v) => (
                  <tr key={v.ai_interview_id} className="border-b border-lightGray">
                    <th scope="row" className="px-6 py-4 text-body14 font-medium text-darkGray">
                      <span className="block">
                        {
                          jobsData?.find((job) => job.job_candidate_id === v.job_candidate_id)
                            ?.organization_name
                        }
                      </span>
                      <span className="block">
                        {
                          jobsData?.find((job) => job.job_candidate_id === v.job_candidate_id)
                            ?.title
                        }
                      </span>
                    </th>
                    <td className="px-6 py-4 text-body12 font-medium text-darkGray capitalize">
                      {v.status}
                    </td>
                    <td className="px-6 py-4 text-body12 font-medium text-darkGray">
                      {v.status === 'failed'
                        ? 'Something went wrong with this interview. You might need to record it again. Sorry for the inconveniece!'
                        : v.total_score_description}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
