import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutSplashScreen } from '../_theme/layout/core';
import { MasterInit } from '../_theme/layout/MasterInit';
import { AuthInit } from './modules/auth';
import Hotjar from '@hotjar/browser';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  Hotjar.init(5235963, 6);
  console.info('Hotjar initialized');
}

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <AuthInit>
        <Outlet />
        <MasterInit />
      </AuthInit>
    </Suspense>
  );
};

export { App };
