/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { useAuth } from '../../auth';
import toast from 'react-hot-toast';
import { Config } from '../../../../config';

interface T {
  handleClose: () => void;
  interviewType: 'VetAI' | 'InterviewAI';
}

const options = [
  'Technical Issues',
  'Background Noise',
  'Nervousness',
  'Misunderstanding of the Question',
  'Incomplete Answer',
  'Accidental End of Interview',
  'Audio/Video Issues',
  'Personal Emergency',
  'Time Constraints',
  'Revised Profile Information',
];

export const RequestAnotherInterview = ({ handleClose, interviewType }: T) => {
  const { currentUser } = useAuth();
  const [reason, setReason] = useState<string>('');
  const [comments, setComments] = useState<string>('');

  const handleRequestAnotherInterview = () => {
    axios
      .post(`${Config.api_url}/candidate/ai-interview/request-another-interview`, {
        candidate_name: `${currentUser?.first_name} ${currentUser?.last_name}`,
        interview_type: interviewType,
        reason,
        comments,
      })
      .then(() => {
        toast.success('Your request has been submitted successfully');
      })
      .catch((e) => {
        console.error('Failed to request another interview', e);
        toast.error('Failed to request another interview');
      });
  };

  return (
    <div className="flex flex-col p-8">
      <h3 className="text-h3 font-extrabold">
        Please select a reason for requesting another interview, and our team will be in touch with
        you shortly!
      </h3>

      <span className="text-left text-darkGray mt-4">Reason</span>
      <Select
        placeholder="Select Reason"
        isClearable={true}
        isMulti={false}
        options={options.map((o: string) => ({ label: o, value: o })) as any}
        defaultValue={reason}
        onChange={(e: SingleValue<any>) => setReason(e?.value as string)}
      />

      <span className="text-left text-darkGray mt-4">Other Comments</span>
      <textarea
        className="form-control"
        placeholder="Enter additional comments"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
      />

      <div className="flex justify-end w-full gap-x-4 mt-8">
        <button
          onClick={handleClose}
          className="btn btn-light main-button btn-active-light !text-darkGray !rounded-[12px]"
        >
          Close
        </button>

        <button
          onClick={() => {
            handleRequestAnotherInterview();
            handleClose();
          }}
          className="btn btn-light main-gradient !text-white main-button btn-active-light !rounded-[12px]"
        >
          Request Another Interview
        </button>
      </div>
    </div>
  );
};
