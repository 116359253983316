import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as InterviewAI from '../../AiInterviews/InterviewAI';
import { GatherFeedback } from '../../AiInterviews/GatherFeedback';
import { RequestAnotherInterview } from '../../AiInterviews/RequestAnotherInterview';
import { InterviewScreen } from '../../AiInterviews/InterviewScreen';
import { PrepareInterview } from '../../AiInterviews/PrepareInterview';
import { useAuth } from '../../../auth';

export interface InterviewAIProps {
  jobCandidateId: string;
  userName: string;
  show: boolean;
  setShow: (show: boolean) => void;
}

type ModalView =
  | 'prepareInterview'
  | 'areYouReady'
  | 'interview'
  | 'completed'
  | 'requestAnother'
  | 'feedback';

export const InterviewAIInterview: FC<InterviewAIProps> = ({
  jobCandidateId,
  userName,
  show,
  setShow,
}: InterviewAIProps) => {
  const { currentUser } = useAuth();
  const [started, setStarted] = useState<boolean>(false);
  const [view, setView] = useState<ModalView>('areYouReady');

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (started) {
        const confirmationMessage =
          'You will need to request another interview if you leave now. Are you sure you want to leave?';
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [started]);

  const handleClose = () => {
    setView('areYouReady');
    setShow(false);
  };

  return (
    <>
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        className={'fade !p-0'}
        animation={true}
        show={show && view !== 'interview'}
      >
        <Modal.Body className="!p-0">
          <span className="top-border block w-full h-[10px] rounded-t-[6px]" />

          {view === 'areYouReady' && (
            <InterviewAI.AreYouReady
              handleStart={() => {
                setView('prepareInterview');
              }}
              handleClose={handleClose}
              candidateName={userName}
            />
          )}

          {view === 'prepareInterview' && (
            <PrepareInterview
              handleStart={() => {
                setView('interview');
                setStarted(true);
              }}
              handleClose={handleClose}
              showPractice={false}
              handleStartPractice={() => {}}
              startText="Start Interview"
              paragraphs={[]}
            />
          )}

          {view === 'completed' && (
            <InterviewAI.Completed handleStartFeedback={() => setView('feedback')} />
          )}

          {view === 'feedback' && (
            <GatherFeedback handleClose={() => setShow(false)} interviewType="InterviewAI" />
          )}

          {view === 'requestAnother' && (
            <RequestAnotherInterview
              handleClose={() => setShow(false)}
              interviewType="InterviewAI"
            />
          )}
        </Modal.Body>
      </Modal>

      {view === 'interview' && (
        // the z index needs to the match the overlay z index of the joyride comp
        <div className="fixed top-0 left-0 w-screen h-screen bg-white z-[199]">
          <InterviewScreen
            defaultQuestions={[]}
            isPractice={false}
            candidateId={currentUser?.id as string}
            jobCandidateId={jobCandidateId}
            interviewType="interview_ai"
            handleCancel={handleClose}
            handleFinish={() => {
              setView('completed');
            }}
          />
        </div>
      )}
    </>
  );
};
