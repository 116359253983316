interface T {
  handleStartFeedback: () => void;
}

export const Completed = ({ handleStartFeedback }: T) => {
  return (
    <div className="flex flex-col items-center px-8 py-2">
      <h3 className="text-h3 font-extrabold mt-8">Congrats on completing your InterviewAI!</h3>
      <p className="text-body14 text-center">
        Your results might take a couple of minutes to show.
      </p>

      <p className="text-body14 text-center">
        In the meantime, we’d love to hear your feedback—it helps us improve the process for
        everyone!
      </p>

      <div className="flex gap-x-2 mb-8">
        <button
          onClick={handleStartFeedback}
          className="btn btn-light btn-active-light !text-darkGray !rounded-[12px]"
        >
          Give Feedback
        </button>
      </div>
    </div>
  );
};
