import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useCallback,
} from 'react';
import { DefaultConfig } from './_LayoutConfig';
import {
  setLayoutIntoLocalStorage,
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './_LayoutSetup';
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
  LayoutType,
  ToolbarType,
} from './_Models';
import { WithChildren } from '../../helpers';
// import { Toolbar } from './Toolbar';
// import { PageTitleWrapper } from './page-title';
import { Link } from 'react-router-dom';
// import { Avatar } from '../../../../app/util/avatar';
// import { useAuth } from '../../../../app/modules/auth';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CandidateBanner from '../components/CandidateBanner';
import { useAuth } from '../../../app/modules/auth';
import { Avatar } from '../../../app/util/avatar';
import { fetchHasUnreadNotifications } from '../../../app/modules/notifications/FetchNotifications';
import { CandidateProfileModel } from '../../../app/modules/candidate/profile/Models';
import axios from 'axios';
import { Config } from '../../../config';
import { useQuery } from 'react-query';
import { PaymentMethod } from '../../../app/modules/candidate/payment/Models';
import { isDefaultProfileImageRegex } from '../../../app/modules/candidate/profile/EditProfile';

export interface LayoutContextModel {
  config: ILayout;
  classes: ILayoutCSSClasses;
  attributes: ILayoutHTMLAttributes;
  cssVariables: ILayoutCSSVariables;
  setLayout: (config: LayoutSetup) => void;
  setLayoutType: (layoutType: LayoutType) => void;
  setToolbarType: (toolbarType: ToolbarType) => void;
  setToolbarButtons: Dispatch<SetStateAction<ReactNode>>;
  toolbarButtons: ReactNode;
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  setLayoutType: (layoutType: LayoutType) => {},
  setToolbarType: (toolbarType: ToolbarType) => {},
  setToolbarButtons: () => <></>,
  toolbarButtons: <></>,
});

export const LayoutToolbarButtons: FC<WithChildren> = ({ children }) => {
  const auth = useAuth();
  const { currentUser } = auth;

  if (currentUser?.user_type === 'customer') {
    return <CustomerLayoutToolbarButtons>{children}</CustomerLayoutToolbarButtons>;
  } else {
    return <CandidateLayoutToolbarButtons>{children}</CandidateLayoutToolbarButtons>;
  }
};

const CustomerLayoutToolbarButtons: FC<WithChildren> = ({ children }) => {
  const auth = useAuth();

  const [unread, setUnread] = useState(false);

  useEffect(() => {
    async function checkUnreadNotifications() {
      const response = await fetchHasUnreadNotifications();
      setUnread(response);
    }

    checkUnreadNotifications();
  }, []);

  return (
    <header className="flex flex-col !w-full mt-8">
      <section className="flex items-start justify-between">
        <div>{children}</div>
        <div className={'d-flex'}>
          <OverlayTrigger
            delay={{ hide: 200, show: 100 }}
            overlay={(props) => (
              <Tooltip className={'tooltip'} {...props}>
                Notifications
              </Tooltip>
            )}
            placement="bottom"
          >
            <Link
              title={'Notifications'}
              to={'/notifications'}
              id={'notifications_wrapper'}
              className={'btn ml-3 p-1 ms-3 me-3 position-relative'}
            >
              <i className="bi bi-bell !text-2xl !text-priBlack" />
              {unread && (
                <span className="position-absolute top-30 start-70 translate-middle p-1 bg-danger border border-light rounded-circle" />
              )}
            </Link>
          </OverlayTrigger>
          <OverlayTrigger
            delay={{ hide: 200, show: 100 }}
            overlay={(props) => (
              <Tooltip className={'tooltip'} {...props}>
                Account
              </Tooltip>
            )}
            placement="bottom"
          >
            <Link to={'/account'} className="btn ml-0 me-0 pri-border !rounded-full !p-[2px]">
              <Avatar
                user={{
                  firstName: auth.currentUser?.first_name as string,
                  lastName: auth.currentUser?.last_name as string,
                  email: auth.currentUser?.email as string,
                }}
              />
            </Link>
          </OverlayTrigger>
        </div>
      </section>
    </header>
  );
};

const CandidateLayoutToolbarButtons: FC<WithChildren> = ({ children }) => {
  const auth = useAuth();
  const { currentUser } = auth;

  const fetchProfile = async (): Promise<CandidateProfileModel> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile`);
    return await response.data.value;
  };

  const { data: profileData } = useQuery(['candidate.profile', currentUser?.id], fetchProfile, {
    staleTime: 60000,
  });

  const fetchBankAccount = async (): Promise<Array<PaymentMethod>> => {
    const response = await axios.get(`${Config.api_url}/candidate/payee`);
    return await response.data.value;
  };

  const { data: paymentData } = useQuery(['candidate.payee.methods'], fetchBankAccount);

  const fetchJob = async (): Promise<{ is_hired: boolean }> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile/check-status`);
    return await response.data.value;
  };

  const { data: jobsData } = useQuery(['profile'], fetchJob);

  const step1Percentage = useMemo(() => {
    let currentCompletion = 0;

    if (profileData?.first_name && profileData?.last_name) {
      currentCompletion += 10;
    }

    if (
      profileData?.skills &&
      profileData?.skills.length > 0 &&
      profileData?.additional_skills &&
      profileData?.additional_skills.length > 0
    ) {
      currentCompletion += 10;
    }

    if (!isDefaultProfileImageRegex.test(profileData?.avatar_url as string)) {
      currentCompletion += 10;
    }

    if (profileData?.years_professional_experience) {
      currentCompletion += 5;
    }

    if (profileData?.start_availability) {
      currentCompletion += 5;
    }

    if (profileData?.location) {
      if (profileData?.location === 'US' || profileData?.location === 'United States') {
        currentCompletion += 5;
        if (profileData?.state && profileData?.city) {
          currentCompletion += 5;
        }
      } else {
        currentCompletion += 10;
      }
    }

    if (profileData?.timezone) {
      currentCompletion += 10;
    }

    if (profileData?.salary_range_in_cents) {
      currentCompletion += 5;
    }

    if (profileData?.headline) {
      currentCompletion += 10;
    }

    if (profileData?.bio) {
      currentCompletion += 10;
    }

    if (profileData?.languages) {
      currentCompletion += 5;
    }

    if (profileData?.work_history && profileData?.work_history?.length > 0) {
      currentCompletion += 5;
    }

    if (profileData?.education && profileData?.education?.length > 0) {
      currentCompletion += 5;
    }

    return currentCompletion;
  }, [profileData]);

  const step2Percentage = profileData?.profile_status?.completion_status?.vet_ai ? 50 : 5;
  const step3Percentage = jobsData && jobsData?.is_hired ? 100 : 5;
  const step4Percentage = paymentData && paymentData?.length > 0 ? 100 : 5;

  return (
    <header className="flex flex-col !w-full mt-8">
      <section className="flex items-start justify-between">
        <div>{children}</div>
        <div className={'d-flex'}>
          <OverlayTrigger
            delay={{ hide: 200, show: 100 }}
            overlay={(props) => (
              <Tooltip className={'tooltip'} {...props}>
                Account
              </Tooltip>
            )}
            placement="bottom"
          >
            <Link to={'/account'} className="btn ml-0 me-0 pri-border !rounded-full !p-[2px]">
              <Avatar
                user={{
                  firstName: auth.currentUser?.first_name as string,
                  lastName: auth.currentUser?.last_name as string,
                  email: auth.currentUser?.email as string,
                }}
              />
            </Link>
          </OverlayTrigger>
        </div>
      </section>

      <section className="!w-full">
        <CandidateBanner
          step1Progress={step1Percentage}
          step2Progress={step2Percentage}
          step3Progress={step3Percentage}
          step4Progress={step4Percentage}
        />
      </section>
    </header>
  );
};

export function useLayout() {
  return useContext(LayoutContext);
}
