import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

const variants = {
  variant: {
    default: 'main-gradient text-primary-foreground main-gradient-hover',
    destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
    outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
    secondary: 'border bg-[#F1F5FE] text-secondary-foreground hover:bg-[#EDF2FE]',
    ghost: 'hover:bg-accent hover:text-accent-foreground',
    link: 'text-primary underline-offset-[4px] hover:underline',
  },
  size: {
    default: 'h-[36px] px-[14px] py-[8px]',
    sm: 'h-[32px] px-[12px] text-[12px]',
    lg: 'h-[40px] px-[32px]',
    icon: 'h-[36px] w-[36px]',
  },
};

const buttonVariants = cva(
  'inline-flex items-center shadow-xs justify-center gap-[8px] whitespace-nowrap rounded-[10px] text-[14px] font-medium transition-colors focus-visible:outline-none focus-visible:ring-[1px] focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-[16px] [&_svg]:shrink-0',
  {
    variants,
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    if (variant === 'outline') {
      return (
        <div className={cn(buttonVariants({ size, className }), 'main-gradient p-[1px]')}>
          <Comp
            className={cn(
              variants.size[size ?? 'default'],
              'bg-[var(--distro-white)] w-full h-full rounded-[9px] text-primary hover:bg-[#f2f2f2]',
              className
            )}
            ref={ref}
            {...props}
          />
        </div>
      );
    }
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
