/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useAuth } from '../../../../../app/modules/auth';
import { Avatar } from '../../../../../app/util/avatar';
import { useNavigate } from 'react-router-dom';
import { CandidateProfileModel } from '../../../../../app/modules/candidate/profile/Models';
import { Config } from '../../../../../config';
import axios from 'axios';
import { useQuery } from 'react-query';

const CandidateSidebarMainMenu = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const isWelcomeUrl = window.location.pathname.includes('/welcome');

  const fetchProfile = async (): Promise<CandidateProfileModel> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile`);
    return await response.data.value;
  };

  const { data } = useQuery(['candidate.profile', currentUser?.id], fetchProfile);

  return (
    <>
      {/*<SidebarMenuItem*/}
      {/*  to='/candidate/dashboard'*/}
      {/*  icon='/media/icons/duotune/art/art002.svg'*/}
      {/*  title='Dashboard'*/}
      {/*  fontIcon='fa-table-columns fa-fw'*/}
      {/*/>*/}
      <div
        className={`w-full h-[62px] px-2 ${
          isWelcomeUrl ? 'main-gradient' : 'bg-priBlack'
        } flex items-center justify-between cursor-pointer`}
        onClick={() => navigate('/candidate/welcome')}
      >
        {data?.avatar_url ? (
          <img
            className="w-[46px] h-[46px] object-cover rounded-full border-[2px] border-white"
            alt="User Profile"
            src={data?.avatar_url}
          />
        ) : (
          <Avatar
            className="w-[46px] h-[46px] flex items-center justify-center text-h3 border-[1px] border-white"
            user={{
              firstName: currentUser?.first_name as string,
              lastName: currentUser?.last_name as string,
              email: currentUser?.email as string,
            }}
          />
        )}

        <span>
          {currentUser?.first_name} {currentUser?.last_name}
        </span>

        <i className="bi bi-chevron-right"></i>
      </div>

      <div className="px-3">
        <SidebarMenuItem to="/candidate/profile" icon="" title="Profile" fontIcon="bi bi-person" />
        <SidebarMenuItem
          to="/candidate/interviews"
          icon="fa-calendar-days"
          title="Interviews"
          fontIcon="bi bi-person-check"
        />
        <SidebarMenuItem
          to="/candidate/jobs"
          icon="bi bi-briefcase"
          title="Jobs"
          fontIcon="bi bi-briefcase"
        />
        <SidebarMenuItem
          to="/candidate/calendar"
          icon="fa-calendar-days"
          title="Calendar"
          fontIcon="bi bi-calendar-date"
        />
        <SidebarMenuItem
          to="/candidate/payment"
          icon=""
          title="Payments"
          fontIcon="bi bi-credit-card"
        />
        <SidebarMenuItem
          to="/candidate/invite"
          icon="fa-bullhorn"
          title="Invitations"
          fontIcon="bi bi-person-plus"
        />
      </div>
    </>
  );
};

export { CandidateSidebarMainMenu };
